//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  useCategory,
  useStoryblokGlobalComponents,
  useTheme,
  useHost,
  useVenue,
  useThemeCategory
} from '@odyssey/realtainment';
import { onSSR } from '@odyssey/core';
import { computed, useContext, ref, watch } from '@nuxtjs/composition-api';
import useEventGrid from '@/composables/useEventGrid';
import { eventSchema } from '@/helpers/json-ld';
import { useCache, CacheTagPrefix } from '@odyssey/realtainment-output-cache';
export default {
  name: 'ProductListingPage',
  components: {
    ProductListingFilters: () =>
      import(/* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/ProductListingFilters'),
    ProductListingGrid: () =>
      import(/* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/ProductListingGrid'),
    ProductTile: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-product-tile" */ '@/components/UI/molecules/ProductTile'),
    STeamEvent: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/STeamEvent'),
    SCarousel: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-carousels" */ '@/components/UI/molecules/carousels/SCarousel'),
    SSection: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SSection'),
    SSectionColumn: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SSectionColumn'),
    SLayoutRow: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SLayoutRow')
  },
  props: {
    slug: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const { categories } = useCategory();
    const { events } = useEventGrid();
    const { result: themes } = useTheme();
    const { result: hosts } = useHost();
    const { result: venues } = useVenue();
    const { result: themeCategories } = useThemeCategory();

    const { route, i18n } = useContext();
    const { globalComponents } = useStoryblokGlobalComponents();

    const category = computed(() => categories.value.find((c) => c.slug === props.slug));
    const categoryDescription = computed(() => category.value?.description?.split('/*BREAK*/'));
    const isKidsEventPage = computed(() => route.value.path.includes('malen-mit-kindern'));
    const isMotivPage = computed(() => route.value.path === '/motiv/');
    const isEventsPage = computed(() => route.value.path === '/events/');

    const inspirations = computed(() => {
      const inspirationsPerSlug = globalComponents.value.inspirationItems?.find(
        (inspiration) => inspiration.path === props.slug || inspiration.isGlobal
      );

      if (inspirationsPerSlug) {
        return inspirationsPerSlug.items.map((item) => ({
          item,
          title: item?.title,
          category: item?.category,
          image: item?.image.filename,
          link: item?.link.cached_url,
          motiveAmount: item?.motivesAmount || item?.motiveAmount,
          zoomEffect: item?.zoomEffect,
          bulletIcon: item?.bulletIcon,
          highlightedText: item?.highlightedText,
          textDescription: item?.textDescription,
          pricePrefix: item?.pricePrefix,
          price: item?.price
        }));
      }

      return [];
    });

    const slug = computed(() => route.value.params.slug);

    const isCategoryRoute = computed(() => categories.value.find((c) => c.slug === slug.value) !== undefined);

    const isCityPage = computed(
      () => isCategoryRoute.value && route.value?.path !== '/online-events/' && !isKidsEventPage.value
    );

    const selectedThemeCategories = computed(() => route.value.query.themeCategories_uuid || []);

    const themeCategoryNames = computed(() =>
      themeCategories.value
        .filter((category) => selectedThemeCategories.value.includes(category.uuid))
        .map((themeCategory) => ' ' + themeCategory.name)
    );

    const title = ref(null);
    const subTitle = ref(null);

    function getHeader() {
      const path = ['events', 'motiv'];

      if (path.includes(props.slug) || isCityPage.value || isKidsEventPage.value)
        subTitle.value = i18n.t('product_page.sub_title');

      if (path.includes(props.slug)) {
        title.value = i18n.tc(`product_page.title.${props.slug}`, themeCategoryNames.value.length ? 2 : 1, {
          themeCategoryNames: themeCategoryNames.value
        });
      } else if (isCityPage.value) {
        title.value = i18n.tc('product_page.title.city', themeCategoryNames.value.length ? 2 : 1, {
          themeCategoryNames: themeCategoryNames.value,
          city: category.value?.name
        });
      } else if (isKidsEventPage.value) {
        title.value = i18n.t('product_page.title.kids');
      }
    }

    onSSR(() => {
      const { addTags } = useCache();
      addTags([{ prefix: CacheTagPrefix.Category, value: props.slug }]);
    });

    watch(
      themeCategoryNames,
      () => {
        getHeader();
      },
      { immediate: true }
    );

    return {
      route,
      inspirations,
      categoryDescription,
      events,
      themes,
      venues,
      hosts,
      category,
      isKidsEventPage,
      isEventsPage,
      isMotivPage,
      title,
      subTitle
    };
  },
  head() {
    const head = this.$nuxtI18nHead({ addDirAttribute: false, addSeoAttributes: true });

    if (this.isEventsPage) {
      const title = this._i18n.t('category.events_page_title');
      const description = this.$config.seo.headDescription;
      return {
        ...head,
        title,
        meta: head.meta.concat([{ hid: 'description', name: 'description', content: description }])
      };
    }

    if (this.isMotivPage) {
      const title = this._i18n.t('category.motiv_page_title');
      const description = this.$config.seo.headDescription;
      return {
        ...head,
        title,
        meta: head.meta.concat([{ hid: 'description', name: 'description', content: description }])
      };
    }

    if (this.isKidsEventPage) {
      const title = this._i18n.t('category.kids_events.meta.title');
      const description = this._i18n.t('category.kids_events.meta.description');
      return {
        ...head,
        title,
        meta: head.meta.concat([{ hid: 'description', name: 'description', content: description }])
      };
    }
    if (this.category) {
      const description =
        this.category.slug === 'online-events'
          ? this._i18n.t('category.meta_online_description')
          : this._i18n.t('category.meta_description', { city: this.category.name });
      const title =
        this.category.slug === 'online-events'
          ? this._i18n.t('category.live_meta_title', { brand: this.$config.seo.defaultTitle })
          : this._i18n.t('category.meta_title', {
              brand: this.$config.seo.defaultTitle,
              city: this.category.name
            });

      let script = [];
      if (Array.isArray(this.events) && this.events.length) {
        script = [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'https://schema.org',
              '@type': 'ItemList',
              itemListElement: this.events.map((event, i) => {
                const theme = this.themes.find((t) => t.uuid === event.eventThemeUuid);
                const venue = this.venues.find((v) => v.uuid === event.venueUuid);
                const host = this.hosts.find((h) => h.uuid === event.hostUuid);

                return {
                  '@type': 'ListItem',
                  position: i + 1,
                  item: eventSchema(this, event, theme, host, venue)
                };
              })
            }
          }
        ];
      }

      return {
        ...head,
        title,
        meta: head.meta.concat([{ hid: 'description', name: 'description', content: description }]),
        script
      };
    }
    return head;
  }
};
