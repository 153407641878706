import { parseISO, parse, addMinutes, fromUnixTime } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { PRODUCT_ONLINE_EVENT_TYPE, hostGetters, themeGetters, venueGetters } from '@odyssey/realtainment';
import { CURRENCY_CODE } from '.';
import de from 'date-fns/locale/de';

const formatDescription = (description) => {
  if (!description) return '';
  return description.replace(/(<([^>]+)>)/gi, '');
};

export const eventUrl = (context, product) => {
  const { $config } = context;
  return `${$config.server.url}/events/${product.slug}/`;
};

export const productOffer = (context, product) => {
  const { $i18n } = context;

  const price = product.price / 100;
  const availability = product.quantity > 0 ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock';

  return {
    offers: {
      '@type': 'Offer',
      priceCurrency: CURRENCY_CODE($i18n),
      price,
      availability,
      url: eventUrl(context, product)
    }
  };
};

export const productLocation = (context, product, venue) => {
  const { $i18n } = context;
  if (product.typeId === PRODUCT_ONLINE_EVENT_TYPE) {
    return {
      '@type': 'VirtualLocation',
      url: eventUrl(context, product)
    };
  }

  if (!venue) {
    return {
      '@type': 'Place',
      name: $i18n.t('venue.undefined_label')
    };
  }

  return {
    '@type': 'Place',
    name: venueGetters.getName(venue),
    address: {
      '@type': 'PostalAddress',
      streetAddress: [venue?.venueStreet, venue?.venueBuildingNumber].join(' '),
      postalCode: venue?.venuePostcode,
      addressLocality: venue?.city?.name,
      addressCountry: venue?.country
    }
  };
};

export const eventSchema = (context, product, theme, host, venue) => {
  const { $config, $i18n } = context;
  const timeZone = $config.server.timeZone;
  if (product.eventTimestamp) {
    const eventDateStart = utcToZonedTime(fromUnixTime(product.eventTimestamp), timeZone);
    const eventDateEnd = addMinutes(eventDateStart, product.eventDuration);

    if (eventDateStart && eventDateEnd) {
      let startDate = format(eventDateStart, 'yyyy-MM-dd\'T\'HH:mm+02:00', { locale: de, timeZone });
      let endDate = format(eventDateEnd, 'yyyy-MM-dd\'T\'HH:mm+02:00', { locale: de, timeZone });

      if (product?.event_plan?.length === 1) {
        startDate = product.event_plan[0].datetime_in_ISO8601;
        const parsedDate = parseISO(startDate);
        endDate = format(addMinutes(parsedDate, product.event_plan[0].duration_in_minutes), 'yyyy-MM-dd\'T\'HH:mm+02:00', { locale: de });
      } else if (product?.event_plan?.length === 2) {
        startDate = product.event_plan[0].datetime_in_ISO8601;
        endDate = product.event_plan[1].datetime_in_ISO8601;
      }

      const schema = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: product.name,
        startDate,
        endDate,
        eventAttendanceMode: product.typeId === PRODUCT_ONLINE_EVENT_TYPE
          ? 'https://schema.org/OnlineEventAttendanceMode'
          : 'https://schema.org/OfflineEventAttendanceMode',
        eventStatus: 'https://schema.org/EventScheduled',
        organizer: {
          '@type': 'Organization',
          name: $config.seo.defaultTitle,
          url: $config.server.url
        },
        location: productLocation(context, product, venue),
        performer: {
          '@type': 'PerformingGroup',
          name: hostGetters.getName(host) ?? $i18n.t('host.undefined_label')
        },
        ...productOffer(context, product)
      };

      if (theme) {
        schema.description = theme?.description ? formatDescription(theme.description) : '';
        schema.image = [themeGetters.getCoverImage(context, theme)];
      }

      return schema;
    }
  }
};

export const productSchema = (context, product) => {
  const { $config } = context;
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.name,
    image: [
      product.image
    ],
    description: formatDescription(product.description),
    sku: product.sku,
    brand: {
      '@type': 'Brand',
      name: $config.seo.defaultTitle
    },
    ...productOffer(context, product)
  };
};
